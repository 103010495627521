import { FC, ReactNode } from "react";
import cn from "clsx";
import { Text } from "../Text/Text";
import style from "./Panel.module.scss";

interface PanelProps {
  testid?: string;
  isDarkHeader?: boolean;
  children: ReactNode;
  title: string | ReactNode;
  icon?: ReactNode;
}

export const Panel: FC<PanelProps> = ({
  title,
  children,
  icon,
  testid = "",
  isDarkHeader = false,
}) => {
  return (
    <div className={style.panel} data-testid={testid}>
      <div className={cn(style.panelHeader, { [style.colored]: isDarkHeader })}>
        <Text variant="span" className={style.title}>
          {title}
        </Text>
        {icon}
      </div>
      <div className={style.content}>{children}</div>
    </div>
  );
};

import {
  BUNDEL_COMPLEMENT_CODE,
  BUNDEL_MEALS_CHOCO,
  BUNDEL_MEALS_CODE,
  BUNDEL_MEALS_DESSERT,
  BUNDEL_MEALS_THE,
  BUNDLE_ATTRIBUTE_DAYS,
  BUNDLE_BREAKFAST_CODE,
  BUNDLE_CHECKOUT_ENABLED,
  BUNDLE_CODE_DEFAULT,
  BUNDLE_CODE_DEFAULT_COUPON_CODE,
  BUNDLE_CODE_MEALS,
  BUNDLE_EXTRA_QUANTITY,
  BUNDLE_INITIAL_CONFIGS,
  BUNDLE_OPTIMUM_TAXON_CODE,
  BUNDLE_ORIGINAL_TAXON_CODE,
  BUNDLE_PROGRAM_DAYS,
  BUNDLE_SNACK_CODE,
  BUNDLE_STAB_TAXON_CODE,
  BUNDLE_TAXON_CONFIGURATION,
  CONFIG_SUBSCRIPTION_TUNNEL_TYPE,
  DEFAULT_PROGRAM_DAYS,
  MEAL_CODE_FOOD_PREFERENCE,
  MEAL_CODE_INGREDIENT_DESCRIPTION,
  MEAL_CODE_NUTRISCORE,
  MEAL_CODE_NUTRITIONAL_VALUE,
  MEAL_CODE_PREPARATION_ADVICE,
  MEAL_CODE_STORAGE_INSTRUCTIONS,
  OPTIMUM_SUMMARY_BG,
  ORIGINAL_SUMMARY_BG,
  STAB_SUMMARY_BG,
  VARIANT_CODE_MAX_WEIGHT,
  VARIANT_CODE_MIN_WEIGHT,
} from "@middleware/constants";
import { getLocale } from "@config/locales";
import {
  AssigningType,
  BundleCodePromotionMap,
  BundlePromotion,
  BundlePromotionDetails,
  CHANNEL_CODES,
  DiscountType,
  DiscountTypeValue,
  EProductVariantType,
  EProgramType,
  ERuleType,
  ERuleTypeValue,
  ESubscriptionState,
  IBundleAttribute,
  IConfiguration,
  IConfigurationResponse,
  ICustomer,
  IMenuResponse,
  IProduct,
  IProductResponse,
  IProgram,
  IPromotionCouponActionResponse,
  IPromotionCouponAssigningActionResponse,
  IPromotionCouponDiscountActionResponse,
  IPromotionCouponRuleResponse,
  IPromotionCouponsResponse,
  IVariant,
  TConfigurationTunnelType,
  TProgramType,
  TSubscriptionState,
} from "@middleware/types";
import { AxiosError, AxiosResponse } from "axios";
import optimumIcon from "@static/images/icons/green-shape.png";
import originalIcon from "@static/images/icons/shape.png";
import { limitText, removeDuplicates } from "./utils";

export const getProductDescription = (product: IProduct, short = true) => {
  return short ? limitText(product.shortDescription, 130) : product.description;
};

export const serializeProduct = (
  bundles: IProductResponse[],
  typeVariant: EProductVariantType,
  isOptimum?: boolean,
): IProduct[] => {
  if (!Array.isArray(bundles)) return [];

  const mapping = {
    [BUNDLE_PROGRAM_DAYS]: "programDays",
    [BUNDLE_CHECKOUT_ENABLED]: "checkoutEnabled",
    [BUNDLE_CODE_DEFAULT]: "isDefault",
    [BUNDLE_CODE_MEALS]: "mealsNumber",
    [BUNDLE_CODE_DEFAULT_COUPON_CODE]: "defaultCouponCode",
    [MEAL_CODE_NUTRISCORE]: "nutriscore",
    [MEAL_CODE_FOOD_PREFERENCE]: "foodPreference",
    [MEAL_CODE_INGREDIENT_DESCRIPTION]: "ingredients",
    [MEAL_CODE_NUTRITIONAL_VALUE]: "nutritional",
    [MEAL_CODE_PREPARATION_ADVICE]: "preparation",
    [MEAL_CODE_STORAGE_INSTRUCTIONS]: "storageInstructions",
    [BUNDLE_TAXON_CONFIGURATION]: "extraTaxonConfiguration",
    [BUNDLE_EXTRA_QUANTITY]: "extraTaxonQuantity",
  };

  const newBundlesFormat = bundles
    .filter(
      (item) =>
        item.variants.filter(
          (variant) =>
            variant.enabled &&
            (variant.type === typeVariant ||
              variant.type === EProductVariantType.ALL ||
              typeVariant === EProductVariantType.ALL),
        ).length > 0,
    )
    .map((bundle) => {
      const attributes = bundle.attributes?.reduce((accumulator, attribute) => {
        if (
          attribute.localeCode !== null &&
          attribute.localeCode !== getLocale()
        )
          return accumulator;

        if (attribute.attributeCode in mapping) {
          return {
            ...accumulator,
            [mapping[attribute.attributeCode as keyof typeof mapping]]:
              attribute.value,
          };
        }

        return accumulator;
      }, {});

      const packaging = bundle.packaging ?? 1;
      const variants = bundle.variants;
      const desktopPosition = bundle.desktopPosition ?? 1;
      const mobilePosition = bundle.mobilePosition ?? 1;
      const enableVariant = getEnabledVariant(
        bundle.variants,
        typeVariant,
        isOptimum,
      );
      const enabled = bundle.enabled && enableVariant?.enabled === true;

      return {
        id: bundle.id,
        code: bundle.code,
        codeVariant: enableVariant?.code ?? "",
        name: bundle.name,
        shortDescription: bundle.shortDescription,
        description: bundle.description,
        ingredients: "",
        nutritional: "",
        preparation: "",
        price: enableVariant ? enableVariant.price / packaging : 0,
        originalPrice: enableVariant
          ? enableVariant.originalPrice / packaging
          : 0,
        isNew: bundle.isNew ?? false,
        priority: bundle.priority ?? 0,
        foodPreference: [],
        isDefault: false,
        programDays: DEFAULT_PROGRAM_DAYS,
        checkoutEnabled: false,
        mealsNumber: 0,
        defaultCouponCode: "",
        nutriscore: [],
        storageInstructions: [],
        extraTaxonConfiguration: { values: [] },
        extraTaxonQuantity: { values: [] },
        image: bundle.images.length > 0 ? bundle.images[0].path : "",
        weight: enableVariant?.weight ?? 0,
        volume: enableVariant?.volume ?? 0,
        packaging,
        desktopPosition,
        mobilePosition,
        recommendedQuantity: 0,
        enabled,
        ...attributes,
        variants,
        productTaxons: bundle.productTaxons,
        taxons: bundle.productTaxons.map(
          (productTaxon) => productTaxon.taxon.code ?? "",
        ),
      };
    });

  return newBundlesFormat;
};

export const getEnabledVariant = (
  variants: IVariant[],
  type: EProductVariantType,
  isOptimum?: boolean,
) => {
  const enableVariant = variants.find(
    (variant) =>
      variant.enabled &&
      (variant.type === type ||
        variant.type === EProductVariantType.ALL ||
        type === EProductVariantType.ALL),
  );

  const optimumVariant = variants.find(
    (variant) => variant.type !== EProductVariantType.ALC,
  );

  return enableVariant
    ? enableVariant
    : isOptimum === true
    ? optimumVariant
    : undefined;
};

export const serializeConfigurations = (
  configurations: IConfigurationResponse[],
  type: TConfigurationTunnelType,
): IConfiguration[] => {
  return configurations
    .map(
      (config): IConfiguration => ({
        label: config.name,
        id: config.taxon.code,
        required: false,
        priority: config.priority,
        visited: false,
        tunnelType: config.tunnelType,
      }),
    )
    .filter((config) => config.tunnelType === type);
};
export const sortBundles = (bundles: IProduct[], ordre = "ASC"): IProduct[] => {
  if (!Array.isArray(bundles)) return [];

  return ordre === "ASC"
    ? bundles.sort((a, b) => (a.mealsNumber > b.mealsNumber ? 1 : -1))
    : bundles.sort((a, b) => (a.mealsNumber < b.mealsNumber ? 1 : -1));
};
export const sortConfigurations = (
  configurations: IConfiguration[],
  ordre = "ASC",
): IConfiguration[] => {
  return ordre === "ASC"
    ? configurations.sort((a, b) => (a.priority > b.priority ? 1 : -1))
    : configurations.sort((a, b) => (a.priority < b.priority ? 1 : -1));
};

export const sortMeals = (meals: IProduct[]): IProduct[] => {
  return meals.sort((a, b) => (a.priority > b.priority ? 1 : -1));
};

export const getProductsFromResponse = <T>(
  productResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
  type: EProductVariantType,
  order = "ASC",
): IProduct[] => {
  return sortBundles(
    serializeProduct(productResponse.data as IProductResponse[], type),
    order,
  );
};

export const getBundleFromResponse = <T>(
  bundleResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
): IProduct => {
  return serializeProduct(
    [bundleResponse.data] as IProductResponse[],
    EProductVariantType.ALL,
  )[0];
};

export const getBundlePromotionsFromResponse = <T>(
  bundlePromotionsResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
): BundleCodePromotionMap => {
  const promotionsResponse = bundlePromotionsResponse.data as BundlePromotion[];

  return promotionsResponse.reduce<BundleCodePromotionMap>((acc, promotion) => {
    if (promotion.discountAmount !== 0) {
      const { bundleCode, ...rest } = promotion;
      acc[bundleCode] = rest;
    }

    return acc;
  }, {});
};

export const getConfigurationFromResponse = <T>(
  configurationResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
  type: TConfigurationTunnelType = CONFIG_SUBSCRIPTION_TUNNEL_TYPE,
  order = "ASC",
): IConfiguration[] => {
  return [
    ...BUNDLE_INITIAL_CONFIGS,
    ...sortConfigurations(
      serializeConfigurations(
        configurationResponse.data as IConfigurationResponse[],
        type,
      ),
      order,
    ),
  ];
};

export const getProductsOfMenu = (
  productResponse: AxiosResponse | AxiosResponse<AxiosError>,
  type: EProductVariantType,
  isOptimum?: boolean,
): IProduct[] => {
  const menuItemsResponse = productResponse.data as IMenuResponse;
  const productsResponse: IProductResponse[] = menuItemsResponse.items.map(
    (item) => {
      return {
        ...item.product,
        isNew: item.new,
        priority: item.priority,
      };
    },
  );

  return sortMeals(serializeProduct(productsResponse, type, isOptimum));
};
export const isPromotionFirstOrder = (
  promotionRules: IPromotionCouponRuleResponse[],
) => {
  const promotionRule = promotionRules.find(
    (promotion) => promotion.type === ERuleType.RULE_FIRST_ORDER,
  );
  if (promotionRule !== undefined)
    return promotionRule.configuration[ERuleTypeValue.NTH] === 1;

  return false;
};
export const getPromotionSubscriptionTypeCode = (
  promotionActions: IPromotionCouponActionResponse[],
): string | null => {
  const promotionGroupAction = promotionActions
    .filter((action): action is IPromotionCouponAssigningActionResponse =>
      Object.values(AssigningType).some((value) => value === action.type),
    )
    .find((action) => action.type === AssigningType.SUBSCRIPTION_ASSIGNER);

  if (promotionGroupAction === undefined) return null;

  return promotionGroupAction.configuration["subscription_type_code"];
};

export const getPromotionGroupCode = (
  promotionActions: IPromotionCouponActionResponse[],
): string | null => {
  const promotionGroupAction = promotionActions
    .filter((action): action is IPromotionCouponAssigningActionResponse =>
      Object.values(AssigningType).some((value) => value === action.type),
    )
    .find((action) => action.type === AssigningType.GROUP_ASSIGNER);

  if (promotionGroupAction === undefined) return null;

  return promotionGroupAction.configuration["group"];
};

export const getPromotionDiscountAmount = (
  discountType: DiscountType,
  promotionAction: IPromotionCouponDiscountActionResponse,
): number => {
  const promotionConfiguration = promotionAction.configuration;

  if (CHANNEL_CODES.DIETBON in promotionConfiguration) {
    const channelConfiguration = promotionConfiguration[CHANNEL_CODES.DIETBON];

    switch (discountType) {
      case DiscountType.ORDER_PERCENTAGE:
      case DiscountType.UNIT_PERCENTAGE:
        return channelConfiguration[DiscountTypeValue.PERCENTAGE];
      case DiscountType.VARIANT_FIXED:
      case DiscountType.ORDER_FIXED:
      case DiscountType.UNIT_FIXED:
        return channelConfiguration[DiscountTypeValue.AMOUNT];
      default:
        return 0;
    }
  }

  switch (discountType) {
    case DiscountType.ORDER_PERCENTAGE:
      return promotionConfiguration[DiscountTypeValue.PERCENTAGE];
    default:
      return 0;
  }
};

export const getPromotionVariants = (
  promotionAction: IPromotionCouponDiscountActionResponse,
): string[] => {
  const promotionConfiguration = promotionAction.configuration;

  if (CHANNEL_CODES.DIETBON in promotionConfiguration) {
    const channelConfiguration = promotionConfiguration[CHANNEL_CODES.DIETBON];

    return channelConfiguration.variants_filter?.variants ?? [];
  }

  return [];
};

export const getPromotionCouponFromResponse = (
  promotionCouponsResponse: IPromotionCouponsResponse,
  promotionCoupon: string,
): BundlePromotionDetails[] | null => {
  const promotionDiscountActions = promotionCouponsResponse.actions
    .filter((action): action is IPromotionCouponDiscountActionResponse =>
      Object.values(DiscountType).some((value) => value === action.type),
    )
    .filter((action) => Object.values(DiscountType).includes(action.type));

  if (promotionDiscountActions.length === 0) return null;

  const groupCode = getPromotionGroupCode(promotionCouponsResponse.actions);
  const subscriptionTypeCode = getPromotionSubscriptionTypeCode(
    promotionCouponsResponse.actions,
  );
  const firstOrder = isPromotionFirstOrder(promotionCouponsResponse.rules);
  const promotionCoupons: BundlePromotionDetails[] = [];

  promotionDiscountActions.map((promotionDiscountAction) => {
    const discountType = promotionDiscountAction.type;
    const discountAmount = getPromotionDiscountAmount(
      discountType,
      promotionDiscountAction,
    );
    promotionCoupons.push({
      promotionCoupon,
      discountType,
      discountAmount,
      firstOrder,
      groupCode,
      subscriptionTypeCode,
      variants: getPromotionVariants(promotionDiscountAction),
    });

    return promotionDiscountAction;
  });

  return promotionCoupons.length > 0 ? promotionCoupons : null;
};

export const getBundleByCode = (
  bundles: IProduct[],
  bundleCode: string,
): IProduct | undefined => {
  return bundles.find((singleBundle) => singleBundle.code === bundleCode);
};

export const getObjectifMin = (variant: IVariant) => {
  return (
    variant.optionValues.find(
      (option) => option.optionCode === VARIANT_CODE_MIN_WEIGHT,
    )?.value ?? 0
  );
};

export const getObjectifMax = (variant: IVariant) => {
  return (
    variant.optionValues.find(
      (option) => option.optionCode === VARIANT_CODE_MAX_WEIGHT,
    )?.value ?? 0
  );
};

export const getObjectifOfVariant = (variant: IVariant) => {
  const minWeight = getObjectifMin(variant);
  const maxWeight = getObjectifMax(variant);

  return `${minWeight}-${maxWeight}`;
};

export const isOriginal = (bundle: IProduct) => {
  return (
    bundle.productTaxons?.some(
      (item) => item.taxon.code === BUNDLE_ORIGINAL_TAXON_CODE,
    ) ?? false
  );
};

export const isOptimum = (bundle: IProduct) => {
  return (
    bundle.productTaxons?.some(
      (item) => item.taxon.code === BUNDLE_OPTIMUM_TAXON_CODE,
    ) ?? false
  );
};

export const isStab = (bundle: IProduct) => {
  return (
    bundle.productTaxons?.some(
      (item) => item.taxon.code === BUNDLE_STAB_TAXON_CODE,
    ) ?? false
  );
};

export const getProductTaxons = (bundle: IProduct) => {
  const original = isOriginal(bundle);
  const optimum = isOptimum(bundle);
  const stab = isStab(bundle);

  return original
    ? EProgramType.ORIGINAL
    : optimum
    ? EProgramType.OPTIMUM
    : stab
    ? EProgramType.STAB
    : EProgramType.NONE;
};

export const getProgramDays = (bundle: IProduct) => {
  return bundle.programDays;
};

export const hasBreakfasts = (variant: IVariant) => {
  return variant.optionValues.some(
    (option) => option.code === BUNDLE_BREAKFAST_CODE,
  );
};

export const hasSnacks = (variant: IVariant) => {
  return variant.optionValues.some(
    (option) => option.code === BUNDLE_SNACK_CODE,
  );
};

export const getAllPrograms = (bundles: IProduct[]) => {
  const programs: IProgram[] = [];
  bundles.map((bundle) => {
    bundle.variants?.map((variant) => {
      programs.push({
        code: variant.code,
        objectif: getObjectifOfVariant(variant),
        productTaxon: getProductTaxons(bundle),
        price: variant.price,
        programDays: getProgramDays(bundle),
        hasSnacks: hasSnacks(variant),
        hasBreakfasts: hasBreakfasts(variant),
        breakfastsAndSnacks: hasBreakfasts(variant) && hasSnacks(variant),
        extraTaxonConfiguration: bundle.extraTaxonConfiguration,
        extraTaxonQuantity: bundle.extraTaxonQuantity,
        mealsNumber: bundle.mealsNumber,
        engagement: variant.subscriptionType?.engagementPeriodNumber ?? 0,
        defaultCouponCode: bundle.defaultCouponCode,
      });

      return variant;
    });

    return bundle;
  });

  return programs.filter(
    (program) =>
      program.productTaxon === EProgramType.ORIGINAL ||
      program.productTaxon === EProgramType.OPTIMUM,
  );
};

export const getStabPrograms = (bundles: IProduct[]) => {
  const programs: IProgram[] = [];
  bundles.map((bundle) => {
    bundle.variants?.map((variant) => {
      programs.push({
        code: variant.code,
        objectif: getObjectifOfVariant(variant),
        productTaxon: getProductTaxons(bundle),
        price: variant.price,
        programDays: getProgramDays(bundle),
        hasSnacks: hasSnacks(variant),
        hasBreakfasts: hasBreakfasts(variant),
        breakfastsAndSnacks: hasBreakfasts(variant) && hasSnacks(variant),
        extraTaxonConfiguration: bundle.extraTaxonConfiguration,
        extraTaxonQuantity: bundle.extraTaxonQuantity,
        mealsNumber: bundle.mealsNumber,
        engagement: 0,
        defaultCouponCode: bundle.defaultCouponCode,
      });

      return variant;
    });

    return bundle;
  });

  return programs.filter(
    (program) => program.productTaxon === EProgramType.STAB,
  );
};

export const defaultSelection = {
  numberOfDays: 6,
  breakfastsAndSnacks: true,
};

export const getObjectifLabel = (objectif: string) => {
  const refPosition = objectif.indexOf(">");

  return refPosition > 0
    ? objectif.substring(refPosition + 1) + " kg et+"
    : objectif + " kg";
};

export const hasMultiplePrograms = (programs: IProgram[]) => {
  const hasOriginal = programs.some(
    (program) => program.productTaxon === EProgramType.ORIGINAL,
  );
  const hasOptimum = programs.some(
    (program) => program.productTaxon === EProgramType.OPTIMUM,
  );

  return hasOriginal && hasOptimum;
};

export const getObjectifPriority = (objectif: string) => {
  return parseInt(objectif.replace("-", "").replace(">", ""));
};

export const getObjectifs = (programs: IProgram[]) => {
  const programsObjectifs = programs.map((program) => program.objectif);
  const objectifs = removeDuplicates(programsObjectifs)
    .map((objectif, index) => {
      return {
        code: objectif,
        label: getObjectifLabel(objectif),
        value: index,
      };
    })
    .sort((a, b) =>
      getObjectifPriority(a.code) > getObjectifPriority(b.code) ? 1 : -1,
    );

  return objectifs;
};

export const handleConfigurationsVisibility = (
  configurations: IConfiguration[] | null,
  bundle: IProgram | undefined,
): IConfiguration[] | null => {
  if (configurations === null || bundle === undefined) return configurations;

  return configurations.filter((config) => {
    if (config.id === BUNDEL_MEALS_CODE) return true;

    if (
      config.id === BUNDLE_BREAKFAST_CODE ||
      config.id === BUNDLE_SNACK_CODE
    ) {
      if (bundle.hasBreakfasts && config.id === BUNDLE_BREAKFAST_CODE)
        return true;

      if (bundle.hasSnacks && config.id === BUNDLE_SNACK_CODE) return true;

      return false;
    }

    const isExist = bundle.extraTaxonQuantity?.values.some(
      (taxonConfig) => taxonConfig.extra === config.id,
    );

    return isExist === true;
  });
};

export const getProgramMealsCount = (program: IProgram) => {
  return [program.mealsNumber];
};

export const getProgramRequiredConfigCount = (
  program: IProgram,
  configCode: string,
) => {
  const ConfigItem = program.extraTaxonConfiguration?.values.find(
    (taxonConfig) => taxonConfig.extra === configCode,
  );
  if (ConfigItem) return [parseInt(ConfigItem.quantity)];

  const configQuantity = program.extraTaxonQuantity?.values.find(
    (taxonConfig) => taxonConfig.extra === configCode,
  );

  if (configQuantity)
    return configQuantity.quantities
      .split(",")
      .filter((val) => val !== "0")
      .map((item) => parseInt(item));

  return [0];
};

export const getProgramConfigCount = (
  program: IProgram,
  configCode: string,
) => {
  const configItem = program.extraTaxonQuantity?.values.find(
    (taxonConfig) => taxonConfig.extra === configCode,
  );

  return configItem
    ? configItem.quantities.split(",").map((item) => parseInt(item))
    : [0];
};

export const getConfigurationsMealsNumber = (
  program: IProgram,
  codeConfig: string,
) => {
  switch (codeConfig) {
    case BUNDEL_MEALS_CODE:
      return getProgramMealsCount(program);
    case BUNDLE_BREAKFAST_CODE:
      return getProgramRequiredConfigCount(program, BUNDLE_BREAKFAST_CODE);
    case BUNDLE_SNACK_CODE:
      return getProgramRequiredConfigCount(program, BUNDLE_SNACK_CODE);
    case BUNDEL_MEALS_DESSERT:
      return getProgramConfigCount(program, BUNDEL_MEALS_DESSERT);
    case BUNDEL_MEALS_CHOCO:
      return getProgramConfigCount(program, BUNDEL_MEALS_CHOCO);
    case BUNDEL_MEALS_THE:
      return getProgramConfigCount(program, BUNDEL_MEALS_THE);
    case BUNDEL_COMPLEMENT_CODE:
      return getProgramConfigCount(program, BUNDEL_COMPLEMENT_CODE);
    default:
      return [0];
  }
};
export const getConfigurationsMeals = (
  program: IProgram,
  configurations: IConfiguration[],
) => {
  const config = configurations.map((configuration) => {
    const codeConfig = configuration.id;
    const mealsNumber = getConfigurationsMealsNumber(program, codeConfig);

    return {
      code: codeConfig,
      mealsNumber,
    };
  });

  return config;
};

export const getProgramType = (
  customer: ICustomer,
): EProgramType | undefined => {
  return customer.currentSubscription?.bundleType ?? EProgramType.NONE;
};
export const getProgramStatusLabel = (
  status: TSubscriptionState | undefined,
) => {
  switch (status) {
    case ESubscriptionState.ON_GOING:
      return "pages.onGoingStatus";
    case ESubscriptionState.PAUSED:
      return "pages.pausedStatus";
    case ESubscriptionState.DONE:
      return "pages.doneStatus";
    case ESubscriptionState.CANCELED:
      return "pages.canceledStatus";
    case ESubscriptionState.CLOSED:
      return "pages.closedStatus";
    case ESubscriptionState.UNPAID:
      return "pages.unpaidStatus";
    default:
      return "";
  }
};
export const getProgramIcon = (programType: TProgramType | undefined) => {
  if (programType === EProgramType.OPTIMUM) return optimumIcon;
  if (programType === EProgramType.ORIGINAL) return originalIcon;

  return null;
};
export const getProgramColor = (programType: TProgramType | undefined) => {
  if (programType === EProgramType.OPTIMUM) return OPTIMUM_SUMMARY_BG;
  if (programType === EProgramType.ORIGINAL) return ORIGINAL_SUMMARY_BG;
  if (programType === EProgramType.STAB) return STAB_SUMMARY_BG;

  return ORIGINAL_SUMMARY_BG;
};
export const getProgramDaysPerWeek = (
  attributes: IBundleAttribute[],
): number | null => {
  const days =
    attributes
      .find((attribute) => attribute.attributeCode === BUNDLE_ATTRIBUTE_DAYS)
      ?.value.toString() ?? null;

  return days !== null ? parseInt(days) : null;
};

export const programContainsTheMeal = (
  attributes: IBundleAttribute[],
  meal: string,
) => {
  const configs = attributes.find(
    (attribute) => attribute.attributeCode === BUNDLE_TAXON_CONFIGURATION,
  )?.value;

  if (Array.isArray(configs)) {
    return configs.some(
      (config) => typeof config === "object" && config.extra === meal,
    );
  }

  return false;
};

export const programContainsTheExtra = (
  attributes: IBundleAttribute[],
  extraCode: string,
) => {
  const configs = attributes.find(
    (attribute) => attribute.attributeCode === BUNDLE_EXTRA_QUANTITY,
  )?.value;

  if (typeof configs === "object" && Array.isArray(configs.values)) {
    return configs.values.some((config) => config.extra === extraCode);
  }

  return false;
};

export const checkIfBreakfastAndSnackAreIncluded = (bundle: IProduct) => {
  const hasSnack = bundle.optionValues?.find(
    (item) => item.optionCode === "taxons" && item.code === BUNDLE_SNACK_CODE,
  );
  const hasBreakfast = bundle.optionValues?.find(
    (item) =>
      item.optionCode === "taxons" && item.code === BUNDLE_BREAKFAST_CODE,
  );

  if (!hasSnack && !hasBreakfast) {
    return {
      key: "pages.withoutBreakfastAndSnack",
    };
  } else if (!hasSnack) {
    return {
      key: "pages.withoutSnack",
    };
  } else if (!hasBreakfast) {
    return {
      key: "pages.withoutBreakfast",
    };
  }

  return {
    key: "pages.withBreakfastAndSnack",
  };
};

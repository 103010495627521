import { useState } from "react";
import {
  generateDate,
  getBasicFormattedDate,
  getFormattedDate,
  getShippingMethodValues,
} from "@middleware/helpers";
import { IOrder, ISchedule } from "@middleware/types";

export const useShippingMethodValues = (
  shippingMethods: ISchedule | undefined,
  order?: IOrder,
) => {
  const daysValues = Object.keys(shippingMethods ?? {}).map((day) => ({
    label: getFormattedDate(day),
    value: day,
    type: "",
  }));
  const firstAvailableDay = daysValues.length > 0 ? daysValues[0].value : "";
  const defaultDay = order
    ? getBasicFormattedDate(generateDate(order.chosenDeliveryDate))
    : firstAvailableDay;
  const methodsInitialValues = shippingMethods
    ? getShippingMethodValues(shippingMethods, defaultDay)
    : [];
  const [methodsValues, setMethodsValues] = useState(methodsInitialValues);

  return { daysValues, methodsValues, setMethodsValues };
};

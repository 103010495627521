export const BIG_TABLET_SIZE = 1023;
export const TABLET_SIZE = 768;
export const SMALL_TABLET_SIZE = 630;
export const LOADER_COLOR = "#5a008c";
export const TOOLTIP_COLOR = "#5a008c";
export const PRIMARY_COLOR = "rgb(118, 191, 114)";
export const GREY_COLOR = "rgb(146, 146, 146)";
export const CODE_TAB_ACCOUNT = "account";
export const CODE_TAB_ORDERS = "orders";
export const CODE_TAB_LOYALTY = "credits";
export const CODE_TAB_ORDERS_COMING = "coming";
export const CODE_TAB_ORDERS_PAST = "past";
export const CODE_TAB_TRACKING = "tracking";
export const CODE_TAB_MY_WEIGHT = "weight";
export const CODE_TAB_MY_DIETITIAN = "dietitian";
export const CODE_TAB_MY_CLUB = "club";
export const CODE_TAB_PROGRAM = "program";
export const CODE_TAB_MY_CURRENT_PROGRAM = "current-program";
export const CODE_TAB_ORDER_PRODUCTS = "order-product";
export const CODE_TAB_MY_INFOS = "infos";
export const CODE_TAB_MY_GUIDE = "guide";
export const CODE_TAB_MY_CREDIT_BALANCE = "balance";
export const CODE_TAB_EARN_CREDIT = "earn-credit";
export const CODE_TAB_MY_DIET_CHAT = "diet-chat";
export const CODE_TAB_SPONSOR = "sponsor";
export const ID_MODAL = "modal-contenaire";
export const BILAN_COUNT_STEPS = 14;
export const BAR_PRISMIC_TYPE = "barre_programme";
export const HEADER_HEIGHT = 100;
export const STICKY_POINT = 10;
export const SCORES_COLORS = ["#F5A3B2", "#F4CAA3", "#F2DE9C", "#ACE1B8"];
export const PROGRESS_CIRCLE_COLORS = ["#5A008C", "#c3a05c"];
export const SUCCESS_CONTAINER =
  "linear-gradient(-45deg, rgba(233, 189, 255, 0.35) 0.72%, rgba(252, 246, 255, 0) 77.27%)";
export const CARD_SUCCESS = "rgba(255, 186, 218, 0.64)";
export const OBESITY_COLOR = "#F5A3B2";
export const OVERWEIGHT_COLOR = "#F2DE9C";
export const NORMALITY_COLOR = "#ACE1B8";
export const OBESITY_DARK_COLOR = "#EF364C";
export const OVERWEIGHT_DARK_COLOR = "#FFD914";
export const NORMALITY_DARK_COLOR = "#46C95B";
export const ORIGINAL_SUMMARY_BG = "#F1E0F9";
export const OPTIMUM_SUMMARY_BG = "#DCFFFB";
export const STAB_SUMMARY_BG = "#FFF8D3";

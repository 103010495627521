import {
  CODE_EXTRA_MAIN_TAXON,
  CODE_MEAL_MAIN_TAXON,
  DEFAULT_SHIPMENT_TIME,
  EShipmentMethod,
  NUMBER_OF_WEEKS_LEAP_YEAR,
  NUMBER_OF_WEEKS_YEAR,
  SMALL_TABLET_SIZE,
  URL_PAGE_CUSTOMER_ORDERS_COMING,
  URL_PAGE_CUSTOMER_ORDERS_PAST,
} from "@middleware/constants";
import {
  BundleCodePromotionMap,
  BundlePromotionDetails,
  DiscountType,
  EDevice,
  EPaymentState,
  EStateOrder,
  IBillingAddress,
  ICustomer,
  IMethodValue,
  IOrder,
  IOrderAddress,
  IOrderItem,
  IPaymentMethodValue,
  IProduct,
  IProgram,
  IRelayPoints,
  ISchedule,
  IShippingAddress,
  IShippingMethodsResponse,
  WindowWithDataLayer,
} from "@middleware/types";
import { client as env } from "@config/env/client";
import {
  getMealsTaxonFromOrder,
  getRemainingDeferral,
  getShippingRelayPointsValues,
  getShippingSlotsValues,
} from "./cart";

export function isEmpty(value: object | string | undefined | null) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}

export function getFirstWord(string: string | undefined) {
  if (string === undefined) return "";

  return string.replace(/ .*/, "");
}

export function getCountryName(countryCode: string): string {
  const regionNames = new Intl.DisplayNames(["fr"], { type: "region" });

  return regionNames.of(countryCode) ?? "";
}

export function limitText(string: string | null, limit = 0) {
  if (string === null) return "";

  return string.substring(0, limit) + (string.length > limit ? "..." : "");
}

export const getFormattedPrice = (price = 0, all = false): string => {
  if (price === 0)
    return (0)
      .toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      })
      .replace(",00", "");

  const formattedPrice = (price / 100)
    .toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
    })
    .replace(/\s/g, "");

  return all ? formattedPrice : formattedPrice.replace(",00", "");
};

export const getFormattedDiscountAmount = (
  discountAmount: number,
  discountType: DiscountType,
) => {
  if (
    discountType === DiscountType.UNIT_PERCENTAGE ||
    discountType === DiscountType.ORDER_PERCENTAGE
  )
    return `-${discountAmount * 100}%`;

  return `-${getFormattedPrice(discountAmount)}`;
};

export const getFormattePromotionBundlePrice = (
  bundlePrice: number,
  discountAmount: number,
  discountType: DiscountType,
) => {
  if (
    discountType === DiscountType.UNIT_PERCENTAGE ||
    discountType === DiscountType.ORDER_PERCENTAGE
  )
    return getFormattedPrice(
      bundlePrice - (discountAmount * 100 * bundlePrice) / 100,
    );

  return getFormattedPrice(bundlePrice - discountAmount);
};

export const getNumericDate = (date: string | null) => {
  const currentDate = generateDate(date);
  const year = currentDate.getFullYear();
  let month = `${currentDate.getMonth() + 1}`;
  let day = `${currentDate.getDate()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

export const getAverageMealPrice = (
  price: number,
  mealsQuantity: number,
): string => {
  return getFormattedPrice(Math.ceil(price / mealsQuantity));
};

export const generateDate = (date?: string | null): Date => {
  if (date === undefined || date === null || date === "") return new Date();
  if (date.length > 19) return new Date(date);

  const isFullDate = date.length > 10;
  const year = parseInt(date.substring(0, 4));
  const month = parseInt(date.substring(5, 7)) - 1;
  const day = parseInt(date.substring(8, 10));
  const hour = isFullDate ? parseInt(date.substring(11, 13)) : 0;
  const minutes = isFullDate ? parseInt(date.substring(14, 16)) : 0;
  const seconds = isFullDate ? parseInt(date.substring(17, 19)) : 0;

  return new Date(year, month, day, hour, minutes, seconds);
};

export const getBasicFormattedDate = (date: Date): string => {
  const d = new Date(date),
    year = d.getFullYear().toString();
  let month = (d.getMonth() + 1).toString(),
    day = d.getDate().toString();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getFormattedDate = (
  date: string | null,
  options: Record<string, string> = {
    weekday: "long",
    month: "long",
    day: "numeric",
  },
): string => {
  return generateDate(date).toLocaleDateString("fr-FR", options);
};

export const getFormattedTime = (
  date: string,
  showMinutes: boolean = false,
) => {
  const hour = generateDate(date).getHours();
  const minutes = generateDate(date).getMinutes();
  if (showMinutes) return `${hour}:${minutes < 10 ? "0" + minutes : minutes}`;

  return `${hour}h`;
};

export const getContactEmail = () => {
  return env.NEXT_PUBLIC_CONTACT_EMAIL;
};

export const getContactNumber = () => {
  return env.NEXT_PUBLIC_CONTACT_NUMBER;
};

export const getNestedProperty = <T>(
  objectIndex: string,
  object: T | undefined,
): T | undefined => {
  return objectIndex
    .replace(/\[([^\]]+)]/g, ".$1")
    .split(".")
    .reduce((currentObject, index) => {
      if (currentObject === undefined || currentObject === null)
        return undefined;

      return currentObject[index as keyof typeof currentObject] as T;
    }, object);
};

export const computeProvinceCode = (
  address: IBillingAddress | IShippingAddress,
) => {
  return ["FR", "BE"].includes(address.countryCode)
    ? address.countryCode + "-" + address.postcode.slice(0, 2)
    : null;
};

export const refactorAddress = (address: IOrderAddress) => {
  const orderAddress = {
    shippingAddress: address.shippingAddress,
    billingAddress:
      address.useDifferentBillingAddress === true
        ? address.billingAddress
        : address.shippingAddress,
  };
  orderAddress.shippingAddress.provinceCode = computeProvinceCode(
    orderAddress.shippingAddress,
  );
  orderAddress.billingAddress.provinceCode = computeProvinceCode(
    orderAddress.billingAddress,
  );

  return orderAddress;
};

export const getGoogleAddressPostcode = (
  place: google.maps.places.PlaceResult,
): string => {
  const postalCode = place.address_components?.find(
    (component) => component.types[0] === "postal_code",
  );

  return postalCode ? postalCode.long_name : "";
};

export const getGoogleAddressCity = (
  place: google.maps.places.PlaceResult,
): string => {
  const city = place.address_components?.find(
    (component) => component.types[0] === "locality",
  );

  return city ? city.long_name : "";
};

export const getOrderExtras = (order: IOrder) => {
  const mealsTaxons = getMealsTaxonFromOrder(order);

  const extrasItems = order.items.filter(
    (item) =>
      item.productTypeTaxon === CODE_EXTRA_MAIN_TAXON &&
      !mealsTaxons.includes(item.productMainTaxon),
  );

  const extrasCount = extrasItems.reduce(
    (accum, item) => (accum += item.quantity * (item.productPackaging ?? 1)),
    0,
  );
  const extrasTotal = extrasItems.reduce(
    (accum, item) => (accum += item.total),
    0,
  );

  return { extrasItems, extrasCount, extrasTotal };
};

export const getOrderMeals = (order: IOrder) => {
  const mealsTaxons = getMealsTaxonFromOrder(order);
  const mealsItems = order.items.filter(
    (item) =>
      item.productTypeTaxon === CODE_MEAL_MAIN_TAXON ||
      mealsTaxons.includes(item.productMainTaxon),
  );
  const mealsCount = mealsItems.reduce(
    (accum, item) => (accum += item.quantity),
    0,
  );

  return { mealsItems, mealsCount };
};

export const addHoursToDate = (date: string, hours: number) => {
  const newDate = generateDate(date);
  newDate.setTime(newDate.getTime() + hours * 60 * 60 * 1000);

  return newDate;
};

export const decreaseHoursToDate = (date: string, hours: number) => {
  const newDate = generateDate(date);
  newDate.setTime(newDate.getTime() - hours * 60 * 60 * 1000);

  return newDate;
};

export const getPreciseSlot = (date: string) => {
  const startHour = generateDate(date).getHours();
  const endHour = addHoursToDate(date, 2).getHours();

  return `${startHour}h - ${endHour}h`;
};

export const getRelayPointsAddress = (relay: IRelayPoints) => {
  return `${relay.adresse1}, ${relay.localite} ${relay.codePostal}`;
};

export const getRelayPointsSchedules = (relay: IRelayPoints) => {
  const MondayHoraires = relay.listeHoraireOuverture.find(
    (item) => item.jour === 1,
  )?.horairesAsString;
  const tuesdayHoraires = relay.listeHoraireOuverture.find(
    (item) => item.jour === 2,
  )?.horairesAsString;
  const wednesdayHoraires = relay.listeHoraireOuverture.find(
    (item) => item.jour === 3,
  )?.horairesAsString;
  const thursdayHoraires = relay.listeHoraireOuverture.find(
    (item) => item.jour === 4,
  )?.horairesAsString;
  const fridayHoraires = relay.listeHoraireOuverture.find(
    (item) => item.jour === 5,
  )?.horairesAsString;
  const saturdayHoraires = relay.listeHoraireOuverture.find(
    (item) => item.jour === 6,
  )?.horairesAsString;
  const sundayHoraires = relay.listeHoraireOuverture.find(
    (item) => item.jour === 7,
  )?.horairesAsString;

  const weekendDays = [
    {
      day: "saturday",
      value: saturdayHoraires,
    },
    {
      day: "sunday",
      value: sundayHoraires,
    },
  ];
  const otherDays = [
    {
      day: "monday",
      value: MondayHoraires,
    },
    {
      day: "tuesday",
      value: tuesdayHoraires,
    },
    {
      day: "wednesday",
      value: wednesdayHoraires,
    },
    {
      day: "thursday",
      value: thursdayHoraires,
    },
    {
      day: "friday",
      value: fridayHoraires,
    },
  ];

  const hasSomeOthersDays = otherDays.every(
    (item) => item.value === otherDays[0].value,
  );

  return hasSomeOthersDays
    ? weekendDays.concat({
        day: "otherDay",
        value: MondayHoraires,
      })
    : otherDays.concat(weekendDays);
};

export const getShipmentDeadline = (date: string) => {
  return decreaseHoursToDate(date, 1).toString();
};

export const getShipmentDeadlineTime = (date: string) => {
  return decreaseHoursToDate(date, 1).getHours();
};

export const getShipmentAddress = (
  shippingAddress: IShippingAddress,
): string => {
  return (
    shippingAddress.street +
    ", " +
    shippingAddress.postcode +
    ", " +
    shippingAddress.city +
    ", " +
    getCountryName(shippingAddress.countryCode)
  );
};

export const getFormatPhoneNumber = (phoneNumber: string) => {
  const numbers = ("" + phoneNumber).replace(/\D/g, "");
  const match = numbers.match(/^(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/);
  if (match) {
    const countryCode = "+" + match[1];

    return [countryCode, match[2], match[3], match[4], match[5], match[6]].join(
      " ",
    );
  }

  return phoneNumber;
};

export const getItemSku = (item: IOrderItem) => {
  return item.productCode;
};

declare const window: WindowWithDataLayer;
export const pushDataLayerSuccess = (cart: IOrder, bundlePrice: number) => {
  const transactionProducts = cart.items.map((item) => ({
    item_id: getItemSku(item),
    item_name: item.productName,
    item_category: item.productTypeTaxon,
    price: item.unitPrice,
    quantity: item.quantity,
  }));

  if (window.dataLayer !== undefined) {
    window.dataLayer = window.dataLayer.length > 0 ? window.dataLayer : [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        currency: "EUR",
        transaction_id: cart.number,
        value: bundlePrice,
        coupon: cart.promotionCoupon?.code ?? "",
        shipping: cart.shippingTotal / 100,
        tax: cart.taxTotal / 100,
        items: transactionProducts,
      },
    });
  }
};

export const pushUserDataLayer = (customer: ICustomer) => {
  if (window.dataLayer !== undefined) {
    window.dataLayer = window.dataLayer.length > 0 ? window.dataLayer : [];
    const hasOrders = customer.paidOrdersCount > 0;

    const dataLayer = {
      user_id: customer.id,
      crm_id: customer.id,
      orders: customer.paidOrdersCount,
      delai_first_order: hasOrders ? getDiffDate(customer.firstOrderDate) : "",
    };

    window.dataLayer.push(dataLayer);
  }
};

export const pushGuestDataLayer = (customerId: number) => {
  if (window.dataLayer !== undefined) {
    window.dataLayer = window.dataLayer.length > 0 ? window.dataLayer : [];

    const dataLayer = {
      user_id: customerId,
      crm_id: customerId,
      orders: 0,
      delai_first_order: "",
    };

    window.dataLayer.push(dataLayer);
  }
};

export const removeDuplicates = (array: string[]) => {
  return Array.from(new Set(array));
};

export const getDiffDate = (date: string) => {
  const nowDate = generateDate();
  const dateDiff = generateDate(date);
  const diffTime = nowDate.getTime() - dateDiff.getTime();
  const diffDays = diffTime / (1000 * 3600 * 24);

  return Math.floor(diffDays);
};

export const getMondayOfDate = (date: string) => {
  const currentDate = generateDate(date);
  const day = currentDate.getDay();
  const currentMonday = generateDate(date);
  if (currentDate.getDay() === 0) {
    currentMonday.setDate(currentDate.getDate() - 6);
  } else {
    currentMonday.setDate(currentDate.getDate() - (day - 1));
  }

  return currentMonday;
};

export const getSundayOfDate = (date: string) => {
  const currentDate = generateDate(date);
  const day = currentDate.getDay();
  const currentSunday = generateDate(date);
  if (currentDate.getDay() === 0) {
    currentSunday.setDate(currentDate.getDate());
  } else {
    currentSunday.setDate(currentDate.getDate() + (7 - day));
  }

  return currentSunday;
};

export const getOrderDetails = (order: IOrder, customer?: ICustomer) => {
  const remainingDeferral = getRemainingDeferral(order);
  const isPaidShipment = order.paymentState === EPaymentState.PAID;
  const isPastShipment = order.state === EStateOrder.ORDER_STATE_FULFILLED;
  const skippedShipment = order.state === EStateOrder.ORDER_STATE_SKIPPED;
  const isCanceledShipment = order.state === EStateOrder.ORDER_STATE_CANCELED;
  const shipmentDeadline = generateDate(order.modificationDeadline);
  const canReactivateShipment = shipmentDeadline > generateDate();
  const canSkipShipment =
    shipmentDeadline > generateDate() &&
    (remainingDeferral > 0 || customer?.engaged === false);
  const disabledShipment =
    skippedShipment ||
    shipmentDeadline < generateDate() ||
    isPaidShipment ||
    isCanceledShipment;
  const paymentDate =
    isPaidShipment && order.payments.length > 0
      ? (order.payments[0].collectionDate as string)
      : order.expectedPaymentDate;

  return {
    isPaidShipment,
    isPastShipment,
    skippedShipment,
    isCanceledShipment,
    shipmentDeadline,
    disabledShipment,
    paymentDate,
    canReactivateShipment,
    canSkipShipment,
  };
};

export const getDefaultShipmentMode = (
  methodsValues: IMethodValue[],
  order?: IOrder,
) => {
  if (order !== undefined && order.shipments.length > 0)
    return order.shipments[0].method.code;

  return methodsValues.length > 0 ? methodsValues[0].value : "";
};

export const getDefaultShipmentType = (
  methodsValues: IMethodValue[],
  order?: IOrder,
) => {
  if (order !== undefined && order.shipments.length > 0)
    return order.shipments[0].method.type;

  return methodsValues.length > 0 ? methodsValues[0].type : "";
};

export const hasDeliverySlots = (
  methods: IShippingMethodsResponse | undefined,
) => {
  if (methods?.schedule === undefined) return false;

  return Object.keys(methods.schedule).length > 0;
};

export const getDefaultShippingSlot = (
  shippingMethods: ISchedule | undefined,
  order?: IOrder,
) => {
  if (shippingMethods === undefined) return undefined;
  if (order === undefined) return undefined;

  const defaultShipmentDay = getNumericDate(order.chosenDeliveryDate ?? null);
  const deliverySlots =
    getShippingSlotsValues(shippingMethods, defaultShipmentDay) ?? [];

  return deliverySlots.find(
    (slot) => slot.value.date === order.chosenDeliveryDate,
  )?.value;
};

export const getDefaultShippingRelayPoint = (
  shippingMethods: ISchedule | undefined,
  order?: IOrder,
) => {
  if (shippingMethods === undefined) return undefined;
  if (order === undefined) return undefined;

  const defaultShipmentDay = getNumericDate(order.chosenDeliveryDate ?? null);
  const deliveryRelayPoints =
    getShippingRelayPointsValues(shippingMethods, defaultShipmentDay) ?? [];

  return deliveryRelayPoints.find(
    (relay) =>
      relay.value === order.shipments[0].details?.relaisDetails?.identifiant,
  )?.value;
};

export const getDefaultShipmentDay = (
  daysValues: IMethodValue[],
  order?: IOrder,
) => {
  if (order?.chosenDeliveryDate !== undefined)
    return getNumericDate(order.chosenDeliveryDate);

  return daysValues.length > 0 ? daysValues[0].value : "";
};

export const getShipmentDateLabel = (order: IOrder) => {
  return getFormattedDate(order.chosenDeliveryDate ?? null, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getShipmentModeLabel = (
  order: IOrder,
  domicilLabel: string,
  preciseLabel: string,
  relaisLabel: JSX.Element,
) => {
  const shipmentMethodType = order.shipments[0].method.type;

  return shipmentMethodType === EShipmentMethod.DOM
    ? domicilLabel + ", " + DEFAULT_SHIPMENT_TIME
    : shipmentMethodType === EShipmentMethod.RELAIS
    ? relaisLabel
    : preciseLabel + ", " + getPreciseSlot(order.chosenDeliveryDate as string);
};

export const getDefaultPaymentMethod = (
  methodsValues: IPaymentMethodValue[],
  order?: IOrder,
) => {
  if (order !== undefined && order.payments.length > 0) {
    const orderPaymentMethodCode = order.payments[0].method.code;
    const hasMethodCode = methodsValues.some(
      (item) => item.method === orderPaymentMethodCode,
    );

    if (hasMethodCode) return orderPaymentMethodCode;
  }

  return methodsValues.length > 0 ? methodsValues[0].method : "";
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToSection = (idSection: string) => {
  const element = document.getElementById(idSection) as HTMLElement;
  if (typeof element.scrollIntoView === "function") {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};

export const getMatchingPromotion = (
  bundle: IProgram,
  bundlePromotions: BundleCodePromotionMap,
) => {
  if (bundle.code in bundlePromotions) {
    return bundlePromotions[bundle.code];
  }
  // parrainage case
  const options = bundle.code.split("_");
  const referralCodeBundle = options[0] + "_" + options[1] + "_" + options[2];
  if (
    referralCodeBundle in bundlePromotions &&
    bundlePromotions[referralCodeBundle].discountType ===
      DiscountType.UNIT_FIXED
  ) {
    return bundlePromotions[referralCodeBundle];
  }

  return undefined;
};

export const getNextMondayOfDate = (date: string | undefined) => {
  if (date === undefined) return getNumericDate(generateDate().toString());

  const numericDate = getNumericDate(date.toString());

  const currentDate = generateDate(numericDate);
  const day = currentDate.getDay();
  const currentMonday = generateDate(numericDate);
  if (day === 0) {
    currentMonday.setDate(currentDate.getDate() + 1);
  } else {
    currentMonday.setDate(currentDate.getDate() + (8 - day));
  }

  return getNumericDate(currentMonday.toString());
};

export const isPastSkippedOrder = (order: IOrder) => {
  const nextMonday = getNextMondayOfDate(order.chosenDeliveryDate);

  return (
    order.state === EStateOrder.ORDER_STATE_SKIPPED &&
    generateDate() > generateDate(nextMonday)
  );
};

export const getNumberFromString = (text: string) => {
  const matches = text.match(/(\d+)/);

  return matches ? parseInt(matches[0]) : 0;
};

export const getAttributeFromorderItem = (
  orderItem: IOrderItem,
  codeAttribute: string,
) => {
  const attribute = orderItem.product.attributes.find(
    (attr) => attr.attributeCode === codeAttribute,
  );

  return attribute ? attribute.value : undefined;
};

export const getNextMenuDate = (): string => {
  const now = generateDate();
  const day = now.getDay();
  const hour = now.getHours();
  const isEndOfWeek = day === 0 || day > 4 || (day === 4 && hour >= 12);
  if (isEndOfWeek) now.setDate(now.getDate() + 5);
  const menuDate = isEndOfWeek ? getBasicFormattedDate(now) : "current";

  return menuDate;
};

export const getSummaryOrdersPageUrl = (cart: IOrder) => {
  const { isPastShipment } = getOrderDetails(cart);
  const sammaryUrl = isPastShipment
    ? URL_PAGE_CUSTOMER_ORDERS_PAST
    : URL_PAGE_CUSTOMER_ORDERS_COMING;

  return sammaryUrl;
};

export const getWeekNumberByDate = (now: Date = new Date()): number => {
  const startOfYear: Date = new Date(now.getFullYear(), 0, 0);
  const days: number = Math.floor(
    (now.getTime() - startOfYear.getTime()) / 86400000,
  );
  const weekNumber: number = Math.ceil((days + 1) / 7);

  return weekNumber;
};

export const getNextWeek = (
  currentYear: number,
  currentWeek: number,
): { week: number; year: number } => {
  if (currentWeek === NUMBER_OF_WEEKS_LEAP_YEAR && !isLeapYear(currentYear)) {
    return { week: 1, year: currentYear + 1 };
  } else if (
    currentWeek === NUMBER_OF_WEEKS_YEAR ||
    (currentWeek === NUMBER_OF_WEEKS_LEAP_YEAR && isLeapYear(currentYear))
  ) {
    return { week: 1, year: currentYear + 1 };
  } else {
    return { week: currentWeek + 1, year: currentYear };
  }
};

const isLeapYear = (year: number): boolean => {
  // Check if a year is a leap year
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const replaceLastString = (
  str: string,
  pattern: any,
  replacement: string,
) => {
  const match =
    typeof pattern === "string"
      ? pattern
      : (str.match(new RegExp(pattern.source, "g")) ?? []).slice(-1)[0];

  if (match === "") return str;

  const last = str.lastIndexOf(match);

  return last !== -1
    ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
    : str;
};

export const removeValuesFromArray = (values: number[], toFind: number) => {
  const newValues = [...values];
  const index = values.indexOf(toFind);
  if (index > -1) {
    newValues.splice(index, 1);
  }

  return newValues;
};

export const calculateAge = (birthday: string) => {
  const birthdayDate = generateDate(birthday);
  const ageDifMs = Date.now() - birthdayDate.getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const openNewTab = (url: string) => {
  if (typeof window !== "undefined") window.open(url, "_blank");
};

export const serializePromotion = (
  promotionsDetails: BundlePromotionDetails[] | null,
) => {
  if (promotionsDetails !== null) {
    const bundlePromotionsFromApi: BundleCodePromotionMap = {};
    promotionsDetails.map((promo) => {
      promo.variants.map((variant) => {
        bundlePromotionsFromApi[variant] = {
          promotionCoupon: promo.promotionCoupon,
          discountType: promo.discountType,
          discountAmount: promo.discountAmount,
          firstOrder: promo.firstOrder,
          groupCode: promo.groupCode,
          subscriptionTypeCode: promo.subscriptionTypeCode,
          variants: promo.variants,
        };

        return variant;
      });

      return promo;
    });

    return bundlePromotionsFromApi;
  }

  return null;
};

export const sortAllMeals = (products: IProduct[]) => {
  const defaultWidth = typeof window !== "undefined" ? window.innerWidth : 0;
  const currentDevice =
    defaultWidth < SMALL_TABLET_SIZE ? EDevice.MOBILE : EDevice.DESKTOP;
  const prods =
    currentDevice === EDevice.MOBILE
      ? products.sort((a, b) => (a.mobilePosition > b.mobilePosition ? 1 : -1))
      : products.sort((a, b) =>
          a.desktopPosition > b.desktopPosition ? 1 : -1,
        );

  return prods;
};

import { UrlObject } from "url";
import { RichTextField } from "@prismicio/client";
import { ValueOf } from "next/dist/shared/lib/constants";
import { TFunctionTrans } from "./i18nextType";

export interface BasicPageProps {
  translation: TFunctionTrans;
}
export interface ITab {
  code: string;
  title: string;
  link: string | UrlObject;
  active: boolean;
}
export type imageProps = {
  width: number;
};
export interface IBilanFields {
  firstName: string;
  email: string;
  mobilePhone?: string;
}
export type TRichTextField = {
  type: string;
  text?: string;
};
export type TScoreData = {
  label?: string;
  color?: string;
};
export type TDietitianData = {
  title?: RichTextField;
  text?: RichTextField;
};
export type TBilanData = {
  currentWeight: number;
  desiredWeight: number;
  height: number;
};
export type TCoordonates = {
  x: number;
  y: number;
};

export enum ERecommendationProccessType {
  ADD = "add",
  REMOVE = "remove",
}

export type TRecommendationProccessType = ValueOf<
  typeof ERecommendationProccessType
>;

import { pushGuestDataLayer, pushUserDataLayer } from "@middleware/helpers";
import { useEffect } from "react";
import { getFromStorage } from "@middleware/helpers/global/sessions";
import { TResponseResultBilan } from "@middleware/types";
import { RESULT_BILAN_DATA } from "@middleware/constants";
import { useCustomer } from "./useCustomer";

export const usePushCustomerDataLayer = () => {
  const { customer } = useCustomer();
  const storedDataBilan = getFromStorage(
    RESULT_BILAN_DATA,
  ) as TResponseResultBilan | null;

  useEffect(() => {
    if (customer) {
      pushUserDataLayer(customer);
    } else if (storedDataBilan !== null && storedDataBilan.customerId > 0) {
      pushGuestDataLayer(storedDataBilan.customerId);
    }
  }, [customer, storedDataBilan]);
};

export const SET_STEP_PROGRAMME = "SET_STEP_PROGRAMME";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const OPEN_DROPDOWN = "OPEN_DROPDOWN";
export const CLOSE_DROPDOWN = "CLOSE_DROPDOWN";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_MODAL_VIEW = "SET_MODAL_VIEW";
export const SET_DETAILS = "SET_DETAILS";
export const REMOVE_DETAILS = "REMOVE_DETAILS";
export const SET_AUTH_DETAILS = "SET_AUTH_DETAILS";
export const REMOVE_AUTH_DETAILS = "REMOVE_AUTH_DETAILS";
export const LOAD_CART = "LOAD_CART";
export const CLEAR_CART = "CLEAR_CART";
export const HOURS_PER_PAGE = 8;
export const SIXTY_MINUTES = 60;
export const SIXTY_MINUTES_TO_A_MILLISECOND = 60000;
export const LAND_MARK_MIN_X = 19;
export const LAND_MARK_MAX_X = 37;
export const LAND_MARK_STEP_X = 3;
export const FIRST_WEIGHT_TO_LOSE = 24;
export const IMC_NORMALITY_MAX_VALUE = 25;
export const IMC_NORMALITY_MIN_VALUE = 10;
export const IMC_OVERWEIGHT_MAX_VALUE = 30;
export const TRUSTPILOT_NUMBER_OF_STARS = 5;

import { useTranslation } from "next-i18next";
import { useAuth } from "./useAuth";
import { useCart } from "./useCart";
import { useGlobalMessaging } from "./useGlobalMessaging";

export const useClearAutentification = () => {
  const { logoutUser } = useAuth();
  const { getNewCart } = useCart();
  const { t } = useTranslation("header");
  const { setMessage } = useGlobalMessaging();

  const resetAutentification = async () => {
    logoutUser();
    setMessage({ text: t("login.logoutSucces"), type: "SUCCESS" });
    await getNewCart();
  };

  return { resetAutentification };
};

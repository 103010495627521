import { ValueOf } from "next/dist/shared/lib/constants";

export type Props = {
  children?: React.ReactNode;
};
export enum EGender {
  MAN = "man",
  WOMAN = "woman",
}
export type TGender = ValueOf<typeof EGender>;

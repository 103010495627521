import { FC, MouseEventHandler, SyntheticEvent } from "react";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";

export interface Props {
  device: "desktop" | "mobile";
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
}

export const Menu: FC<Props> = ({ device, showModal, logout }) => {
  return (
    <>
      {device === "desktop" ? (
        // Desktop Menu
        <DesktopMenu />
      ) : (
        // Mobile Menu
        <MobileMenu showModal={showModal} logout={logout} />
      )}
    </>
  );
};

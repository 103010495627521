import cn from "clsx";
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  JSXElementConstructor,
} from "react";
import s from "./Button.module.scss";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  className?: string;
  variant?: "primary" | "secondary" | "secondaryGreen";
  active?: boolean;
  type?: "submit" | "reset" | "button";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component?: string | JSXElementConstructor<any>;
  width?: string | number;
  loading?: boolean;
  disabled?: boolean;
  puce?: boolean;
}

export const Button: React.FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = "primary",
    children,
    active,
    width,
    loading = false,
    disabled = false,
    style = {},
    Component = "button",
    ...rest
  } = props;

  const rootClassName = cn(
    s.root,
    {
      [s.secondary]: variant === "secondary",
      [s.secondaryGreen]: variant === "secondaryGreen",
      [s.loading]: loading,
      [s.disabled]: disabled,
    },
    className,
  );

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={buttonRef}
      className={rootClassName}
      disabled={disabled}
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      <>{children}</>
    </Component>
  );
});

export default Button;

import WrapApi from "@middleware/helpers/api/wrapApi.class";
import { TResponseResultBilan, TResultsBilanData } from "@middleware/types";
import { AxiosResponse } from "axios";

export default class Bilan extends WrapApi {
  getResultBilan = async (
    customerId: number,
  ): Promise<TResponseResultBilan | undefined> => {
    const result = await this.getResource(
      `shop/customers/${customerId}/evaluation`,
    );

    if (result.status !== 200) return;

    return result.data as TResponseResultBilan;
  };

  saveBilanResult = async (data: TResultsBilanData): Promise<AxiosResponse> => {
    return await this.postResource(`shop/customers/evaluation/`, data);
  };
}

import { ICreditTransfer } from "@middleware/types";
import { generateDate } from "./utils";

export const sortCreditTransfers = (creditTransfers: ICreditTransfer[]) => {
  return creditTransfers.sort((a, b) =>
    generateDate(a.transferDate).getTime() <
    generateDate(b.transferDate).getTime()
      ? 1
      : -1,
  );
};

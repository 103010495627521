export const BUNDLE_PROGRAM_DAYS = "program_days";
export const BUNDLE_ORIGINAL_TAXON_CODE = "original_program";
export const BUNDLE_OPTIMUM_TAXON_CODE = "optimum_program";
export const BUNDLE_STAB_TAXON_CODE = "stabilisation_program";
export const BUNDLE_STAB_ATTRIBUTE_CODE = "stabilisation";
export const BUNDLE_ATTRIBUTE_TYPE = "bundle_type";
export const VARIANT_CODE_MIN_WEIGHT = "min_weight_loss";
export const VARIANT_CODE_MAX_WEIGHT = "max_weight_loss";
export const BUNDLE_CODE_MEALS = "min_meals_number";
export const BUNDLE_CODE_MEALS_MAX = "max_meals_number";
export const BUNDLE_CODE_DEFAULT = "is_default";
export const BUNDLE_CHECKOUT_ENABLED = "checkout_enabled";
export const BUNDLE_TAXON_CONFIGURATION = "extra_taxon_configuration";
export const BUNDLE_EXTRA_QUANTITY = "extra_taxon_quantities";
export const BUNDLE_CODE_DEFAULT_COUPON_CODE = "default_coupon_code";
export const MEAL_CODE_NUTRISCORE = "nutri_score";
export const MEAL_CODE_FOOD_PREFERENCE = "food_preference";
export const MEAL_CODE_INGREDIENT_DESCRIPTION = "ingredient_description";
export const MEAL_CODE_NUTRITIONAL_VALUE = "nutritional_value";
export const MEAL_CODE_PREPARATION_ADVICE = "preparation_advice";
export const CODE_BUNDLE_MAIN_TAXON = "bundle";
export const CODE_MEAL_MAIN_TAXON = "meal";
export const CODE_EXTRA_MAIN_TAXON = "extra";
export const CODE_LOYALTY = "CREDIT";
export const MEAL_CODE_STORAGE_INSTRUCTIONS = "storage_instructions";
export const OBJECTIF_STEP_SECTION = 1;
export const FICHES_STEP_SECTION = 2;
export const BUNDLE_BREAKFAST_CODE = "breakfast";
export const BUNDLE_SNACK_CODE = "snack";
export const BUNDEL_MEALS_CODE = "meal";
export const BUNDEL_COMPLEMENT_CODE = "complement";
export const BUNDEL_MEALS_DESSERT = "dessert";
export const BUNDEL_MEALS_CHOCO = "chocolate";
export const BUNDEL_MEALS_THE = "infusion";
export const BUNDLE_INITIAL_CONFIGS = [
  { label: "Plats", id: "meal", required: true, priority: -3 },
  { label: "Petits-déj", id: "breakfast", required: true, priority: -2 },
  { label: "Collations", id: "snack", required: true, priority: -1 },
];
export const DEFAULT_PROGRAM_DAYS = 6;
export const PERCENTAGE_TOTAL = 100;
export const SCORES_VALUES = [28, 12, 35, 25];
export const SCORES_LABELS = [
  "pages.bad",
  "pages.medium",
  "pages.good",
  "pages.veryGood",
];
export const CONFIG_SUBSCRIPTION_TUNNEL_TYPE = "subscription";
export const CONFIG_RETAIL_TUNNEL_TYPE = "retail";
export const PRODUCT_MAX_INCREMENT = 10;
export const PRODUCT_MAX_INCREMENT_OPTIMUM = 4;
export const RECOMMENDED_PRODUCT_MAX_INCREMENT = 4;
export const ORDER_ORIGINAL_CODE = "original";
export const ORDER_OPTIMUM_CODE = "optimum";
export const STAB_MEALS_BY_DAY = 4;
export const BUNDLE_ATTRIBUTE_DAYS = "program_days";

import { ESubscriptionState, SponsorshipDataType } from "@middleware/types";
import WrapApi from "@middleware/helpers/api/wrapApi.class";
import { AxiosResponse, HttpStatusCode } from "axios";
import {
  getOptimumQuizResponses,
  getRecommendations,
  getTasks,
} from "@middleware/helpers";
import { QuizType } from "@middleware/types/global/successTypes";
import {
  IChangePasswordData,
  ICustomer,
  IOptimumQuizApi,
  IOptimumRecommendations,
  IOptimumRecommendationsApi,
  IOptimumRecommendedProductApi,
  IQuizResponse,
  IQuizResponseApi,
  IRegularizationAmount,
  TResponseError,
  TResultsRoas,
} from "./../types/global/communsType";

export default class Customer extends WrapApi {
  getCustomer = async (customerId: number): Promise<ICustomer | undefined> => {
    const result = await this.getResource("shop/customers/{id}", {
      id: customerId,
    });

    if (result.status !== 200) return undefined;

    return result.data as ICustomer;
  };

  updateCustomer = async (customer: ICustomer): Promise<AxiosResponse> => {
    return await this.putResource(
      "shop/customers/{id}",
      {
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phoneNumber: customer.phoneNumber,
        mobilePhone: customer.mobilePhone,
        email: customer.email,
        excludedFoodPreferences: customer.excludedFoodPreferences,
      },
      {
        id: customer.id,
      },
    );
  };
  changePassword = async (
    customerId: number,
    passwords: IChangePasswordData,
  ): Promise<AxiosResponse> => {
    return await this.putResource("shop/customers/{id}/password", passwords, {
      id: customerId,
    });
  };

  pauseSubscription = async (
    subscriptionId: number,
    reason: string,
    vote: number,
  ): Promise<AxiosResponse> => {
    return await this.patchResource(
      "shop/subscriptions/{id}",
      { state: ESubscriptionState.PAUSED },
      { id: subscriptionId },
    );
  };

  unPauseSubscription = async (
    subscriptionId: number,
  ): Promise<AxiosResponse> => {
    return await this.patchResource(
      "shop/subscriptions/{id}",
      { state: ESubscriptionState.ON_GOING },
      { id: subscriptionId },
    );
  };

  stopSubscription = async (subscriptionId: number): Promise<AxiosResponse> => {
    return await this.patchResource(
      "shop/subscriptions/{id}",
      { state: ESubscriptionState.CLOSED },
      { id: subscriptionId },
    );
  };

  sponsorship = async (
    customerId: number,
    data: SponsorshipDataType,
  ): Promise<AxiosResponse> => {
    return await this.postResource(
      "shop/customers/{id}/sponsorship-email",
      data,
      { id: customerId },
    );
  };

  getOptimumTasks = async (customerId: number): Promise<QuizType> => {
    const response = await this.getResource(
      "shop/customers/{id}/optimum/tasks",
      {
        id: customerId,
      },
    );

    return getTasks(response.data as IOptimumQuizApi[]);
  };

  saveOptimumTask = async (
    customerId: number,
    taskId: string,
    questionId: string,
    answer: string,
  ): Promise<string | TResponseError> => {
    const response = await this.postResource(
      "shop/customers/{id}/optimum/tasks/{taskId}/answer",
      {
        question_id: questionId,
        answer: answer,
      },
      {
        id: customerId,
        taskId: taskId,
      },
    );

    return response.data as string | TResponseError;
  };
  completeOptimumTask = async (customerId: number) => {
    const response = await this.putResource(
      "shop/customers/{id}/optimum/tasks/complete",
      undefined,
      {
        id: customerId,
      },
    );

    return response;
  };
  initializeOptimumPrograms = async (customerId: number) => {
    const response = await this.putResource(
      "shop/customers/{id}/optimum/initialize-orders",
      undefined,
      {
        id: customerId,
      },
    );

    return response;
  };

  getOptimumRecommendations = async (
    customerId: number,
    orderToken: string,
  ): Promise<IOptimumRecommendations> => {
    const response = await this.getResource(
      "shop/customers/{id}/optimum/recommendations/{token}",
      {
        id: customerId,
        token: orderToken,
      },
    );

    return getRecommendations(response.data as IOptimumRecommendationsApi);
  };
  getQuizResponses = async (customerId: number): Promise<IQuizResponse[]> => {
    const response = await this.getResource(
      "shop/customers/{id}/optimum/tasks/answers",
      {
        id: customerId,
      },
    );
    if (response.status === HttpStatusCode.Ok)
      return getOptimumQuizResponses(response.data as IQuizResponseApi[]);

    return [];
  };
  getRecommendedProductToDelete = async (
    customerId: number,
    orderToken: string,
    productCode: string,
  ): Promise<IOptimumRecommendedProductApi[] | TResponseError> => {
    const response = await this.getResource(
      "shop/customers/{id}/optimum/recommendations/{token}/{productCode}/add",
      {
        id: customerId,
        token: orderToken,
        productCode: productCode,
      },
    );

    if (response.status !== 200) return response.data as TResponseError;

    return response.data as IOptimumRecommendedProductApi[];
  };
  getRecommendedProductToAdd = async (
    customerId: number,
    orderToken: string,
    productCode: string,
  ): Promise<IOptimumRecommendedProductApi[] | TResponseError> => {
    const response = await this.getResource(
      "shop/customers/{id}/optimum/recommendations/{token}/{productCode}/remove",
      {
        id: customerId,
        token: orderToken,
        productCode: productCode,
      },
    );

    if (response.status !== 200) return response.data as TResponseError;

    return response.data as IOptimumRecommendedProductApi[];
  };
  getRecommendedProductToReplace = async (
    customerId: number,
    orderToken: string,
    orderItemIdToReduce: number,
    productVariantCodeToAdd: string,
  ): Promise<AxiosResponse | null> => {
    const response = await this.putResource(
      "shop/customers/{id}/optimum/recommendations/{orderTokenValue}/replace",
      {
        orderItemIdToReduce,
        productVariantCodeToAdd,
      },
      { id: customerId, orderTokenValue: orderToken },
    );

    if (response.status !== 200) return null;

    return response;
  };
  getSubscriptionChangeCost = async (
    customerId: number,
    bundleVariantCode: string,
  ): Promise<IRegularizationAmount> => {
    const response = await this.getResource(
      "shop/customers/{id}/subscription/change-cost",
      {
        id: customerId,
      },
      {
        bundleVariantCode,
      },
    );

    return response.data as IRegularizationAmount;
  };

  applyCreditToOrder = async (
    customer: ICustomer,
    token: string,
    credits: number,
  ): Promise<AxiosResponse> => {
    return await this.putResource(
      "shop/customers/{id}/orders/{tokenValue}/credits/apply",
      {
        credits,
      },
      {
        id: customer.id,
        tokenValue: token,
      },
    );
  };

  getOrderRoas = async (
    customerId: number,
    orderToken: string,
  ): Promise<TResultsRoas> => {
    const response = await this.getResource(
      "shop/customers/{id}/orders/{tokenValue}/roas",
      {
        id: customerId,
        tokenValue: orderToken,
      },
    );

    return response.data as TResultsRoas;
  };
}

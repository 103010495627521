import { useAuth, useCart, usePushCustomerDataLayer } from "@middleware/hooks";
import { LayoutProps } from "@middleware/types";
import { useEffect } from "react";
import { URL_PAGE_IMPERSONATE } from "@middleware/constants";
import { useRouter } from "next/router";

const CoreApp: LayoutProps = ({ children }) => {
  const { loadCart } = useCart();
  const { pathname } = useRouter();
  const { user, setConnectedCustomer } = useAuth();
  usePushCustomerDataLayer();

  useEffect(() => {
    void loadCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && pathname !== URL_PAGE_IMPERSONATE) {
      setConnectedCustomer(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{children}</>;
};

export default CoreApp;
